import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Storage } from "@aws-amplify/storage";
import { Button, Spinner } from "reactstrap";
import { X } from "react-feather";
import { toast } from "react-toastify";

const WrapperImageField = styled.div`
  .image_text {
    font: normal normal medium 22px/30px Kanit;
  }
  .removeImageButton {
    position: absolute;
    display: none;
    padding: 0.5rem;
    border: none;
    background-color: transparent !important;
    font: normal normal bold 17px/20px Noto Sans;
    border-color: none !important ;
    color: #ff8744 !important;
    right: 15px;
    z-index: 10;
    top: 6px;
  }
  .dropZone_Box {
    height: 328px;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 3px solid #707070;
  }
  .image_text strong {
    color: #ff8341;
  }
  .dropZone_Box svg {
    width: 56px;
    height: 56px;
  }
  .dropImageBx {
    display: inline-flex;
    border-radius: 5px;
    width: 130px;
    height: 130px;
    cursor: pointer;
    //border: 3px solid #707070;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 4px;
    box-sizing: border-box;
  }
  .mainImageDiv:hover .profileImageBackground {
    border-radius: 50%;
    background-color: #000000;
  }
  .mainImageDiv:hover .hoverImageBackground {
    border-radius: 10px;
    background-color: #000000;
  }
  .mainImageDiv:hover img {
    opacity: 50%;
    /* background: rgba(0 , 0 , 0 , 0.5); */
    transition: opacity 1s;
  }
  .mainImageDiv:hover .editImageText {
    display: block;
  }
  .mainImageDiv:hover .removeImageButton {
    display: block;
  }
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    .preview_box li {
      width: 80px;
      height: 80px;
    }
  }
  @media screen and (max-width: 991px) {
    .preview_box li {
      width: 60px;
      height: 60px;
      background-size: 35px;
    }
    .dropZone_Box {
      height: 40vh;
    }
    .image_text {
      font: normal normal 500 14px/16px Kanit;
    }
    .dropZone_Box svg {
      width: 45px;
      height: 45px;
    }
  }
  @media screen and (max-width: 767px) {
    .preview_box li {
      width: 60px;
      height: 60px;
      background-size: 30px;
    }
    .dropZone_Box {
      height: 30vh;
    }
    .image_text {
      font: normal normal 500 14px/16px Kanit;
    }
    .dropZone_Box svg {
      width: 45px;
      height: 45px;
    }
  }
`;

const thumbStyles = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "140px",
  height: "140px",
  backgroundSize: "contain",
};

const thumb = {
  display: "inline-flex",
  marginBottom: 8,
  marginRight: 8,
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const thumbInnerBorderRadius = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  borderRadius: "50%",
};
const img = {
  display: "block",
  borderRadius: "10px",
  width: "100%",
  height: "100%",
};
const imgBorderRadius = {
  display: "block",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
};

const Thumbs = ({
  file,
  multiple,
  editedFileNameInitialValue,
  profileImage,
}) => (
  <div style={thumb} key={file?.name}>
    <div
      className="hoverImageBackground"
      style={profileImage ? thumbInnerBorderRadius : thumbInner}
    >
      <img
        src={
          file?.preview
            ? file?.preview
            : multiple
            ? file?.presignedUrl
            : editedFileNameInitialValue
        }
        style={img}
        alt={file.name}
      />
    </div>
  </div>
);

function Dropzone(props) {
  const thumbsContainer = {
    backgroundImage: `url('${!props?.isProfile && props?.bg_plus}')`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
  };

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (props.defaultImages?.length > 0) {
      setFiles(props.defaultImages);
    } else if (props.defaultImages?.length === 0) {
      setFiles([]);
    }
  }, [props.defaultImages]);

  const handleUpload = (acceptedFiles) => {
    props.setImageSpinner(true);

    const specificDate = new Date();
    const unixTimestampSeconds = Math.floor(specificDate.getTime() / 1000);
    const extension = acceptedFiles?.name.split(".").pop();

    if (props.onlyImage && acceptedFiles?.type.includes("image")) {
      Storage.put(
        `temp/${props.imageName}_${props.randomNumber}_${unixTimestampSeconds}.${extension}`,
        acceptedFiles,
        {
          contentType: acceptedFiles.type,
        }
      )
        .then((res) => {
          props.setImageSpinner(false);

          props.fileName(
            `${props.imageName}_${props.randomNumber}_${unixTimestampSeconds}.${extension}`,
            acceptedFiles.type
          );

          if (props.multiple) {
            setFiles([
              ...files,
              Object.assign(acceptedFiles, {
                preview: URL.createObjectURL(acceptedFiles),
              }),
            ]);
          } else {
            setFiles([
              Object.assign(acceptedFiles, {
                preview: URL.createObjectURL(acceptedFiles),
              }),
            ]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please upload image only");
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "",
    onDrop: (acceptedFiles) => {
      handleUpload(acceptedFiles[0]);
    },
  });
  const removeFile = (file) => {
    props.removeFile(file?.name);
    if (props?.type) {
      props?.setDeletedImages((prev) => [...prev, file?.name]);
    }
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  useEffect(
    () => () => {
      files?.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const ref = useRef();

  return (
    <WrapperImageField>
      <div
        {...getRootProps({ className: "dropzone" })}
        onClick={(e) => e.stopPropagation}
      >
        <div onClick={open} className="d-none" ref={ref}>
          <input
            {...getInputProps()}
            accept={props.acceptFile}
            disabled={props?.disabled}
          />
          <div></div>
        </div>
      </div>
      <aside>
        {/* files.length > 0 && */}
        <div className="d-flex align-items-center flex-wrap gap-2">
          {props.multiple ? (
            <>
              {files?.map((file, idx) => (
                <div key={idx} className="position-relative mainImageDiv">
                  <Button
                    className="removeImageButton"
                    onClick={(e) => {
                      removeFile(file);
                    }}
                  >
                    <X color="#ff8744" stroke-width="3" />
                  </Button>
                  <div
                    style={{ ...thumbStyles }}
                    className="dropImageBx "
                    onClick={() => ref.current.click()}
                  >
                    <div className="w-100 h-100 profileHoverBackground">
                      {files.length > 0 ? (
                        <>
                          <Thumbs
                            file={file}
                            multiple={props.multiple}
                            editedFileNameInitialValue={
                              props?.editedFileNameInitialValue
                            }
                          />
                        </>
                      ) : props.editTrue === "edit" ? (
                        <>
                          <img
                            src={props?.editedFileNameInitialValue}
                            style={img}
                            alt="Edit"
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}

              {props.imageSpinner ? (
                <div className="d-flex align-items-center p-4">
                  <Spinner
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                    color="primary"
                  />
                </div>
              ) : (
                <div
                  style={{ ...thumbStyles, ...thumbsContainer }}
                  className="dropImageBx cursor-pointer"
                  onClick={() => ref.current.click()}
                />
              )}
            </>
          ) : (
            <div className="position-relative">
              {console.log("files", files, files?.length)}
              {files?.length > 0 || props?.editedFileNameInitialValue ? (
                <Button
                  className="removeImageButton"
                  onClick={(e) => {
                    removeFile(files?.name);
                  }}
                >
                  <X color="#ff8744" stroke-width="3" />
                </Button>
              ) : (
                ""
              )}
              {props.imageSpinner ? (
                <div className="d-flex align-items-center p-4">
                  <Spinner
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                    color="primary"
                  />
                </div>
              ) : (
                <div
                  style={{ ...thumbStyles, ...thumbsContainer }}
                  className="dropImageBx cursor-pointer"
                  onClick={() => ref.current.click()}
                >
                  {files?.length > 0 || props?.editedFileNameInitialValue ? (
                    <div className="w-100 h-100">
                      {files.length > 0 ? (
                        files?.map((file) => (
                          <Thumbs
                            file={file}
                            profileImage={props.profileImage}
                            editedFileNameInitialValue={
                              props?.editedFileNameInitialValue
                            }
                          />
                        ))
                      ) : props.editTrue === "edit" ? (
                        <img
                          src={props?.editedFileNameInitialValue}
                          style={props?.profileImage ? imgBorderRadius : img}
                          alt="Edit"
                        />
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </aside>
    </WrapperImageField>
  );
}

export default Dropzone;

import { createSlice } from "@reduxjs/toolkit";

const facilitySlice = createSlice({
  name: "trustRegister",
  initialState: {
    trustName: "",
    trustEmail: "",
    trustNumber: "",
    trustType: "",
    about: "",
    city: "",
    state: "",
    location: "",
    longitude: "",
    latitude: "",
    trustFacilities: [],
  },
  reducers: {
    addFacility(state, action) {
      state.trustFacilities = [...state.trustFacilities, action.payload];
    },
    removeFacility(state, action) {
      const a = [...state.trustFacilities];
      a.splice(action.payload, 1);
      state.trustFacilities = a;
    },
    clearFacilities(state) {
      state.trustFacilities = [];
    },

    handleRegister(reduxState, action) {
      const {
        trustName,
        trustEmail,
        trustNumber,
        trustCountryCode,
        trustDialCode,
        name,
        email,
        facebookLink,
        address,
        domainName,
        countryCode,
        dialCode,
        mobileNumber,
        trustType,
        about,
        city,
        state,
        location,
        longitude,
        latitude,
      } = action.payload;
      reduxState.trustName = trustName;
      reduxState.trustEmail = trustEmail;
      reduxState.trustNumber = trustNumber;
      reduxState.trustCountryCode = trustCountryCode;
      reduxState.trustDialCode = trustDialCode;
      reduxState.facebookLink = facebookLink;
      reduxState.address = address;
      reduxState.name = name;
      reduxState.email = email;
      reduxState.domainName = domainName;
      reduxState.countryCode = countryCode;
      reduxState.dialCode = dialCode;
      reduxState.mobileNumber = mobileNumber;
      reduxState.trustType = trustType;
      reduxState.about = about;
      reduxState.city = city;
      reduxState.state = state;
      reduxState.location = location;
      reduxState.latitude = latitude;
      reduxState.longitude = longitude;
    },
    clearHandleRegister(reduxState) {
      reduxState.trustName = "";
      reduxState.trustEmail = "";
      reduxState.trustNumber = "";
      reduxState.trustCountryCode = "";
      reduxState.facebookLink = "";
      reduxState.address = "";
      reduxState.trustDialCode = "";
      reduxState.name = "";
      reduxState.email = "";
      reduxState.domainName = "";
      reduxState.countryCode = "";
      reduxState.dialCode = "";
      reduxState.mobileNumber = "";
      reduxState.trustType = "";
      reduxState.about = "";
      reduxState.city = "";
      reduxState.state = "";
      reduxState.location = "";
      reduxState.latitude = "";
      reduxState.longitude = "";
    },
  },
});

export const {
  addFacility,
  clearFacilities,
  removeFacility,
  handleRegister,
  clearHandleRegister,
} = facilitySlice.actions;

export default facilitySlice.reducer;

import "bootstrap/dist/css/bootstrap.min.css";
import RoutePath from "./assets/route";
import "./App.css"  
import  configureAmplify from "./aws/awsPool";

function App() {
  configureAmplify();
  return (
    <div>
      <RoutePath />
    </div>
  );
}

export default App;

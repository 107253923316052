import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL =  process.env.REACT_APP_API_BASE_URL;

const REGISTRATION = `/admin/public/register`;
const trustTypes = `/admin/public/trust-type`;
const cityState = `/admin/state`;
const checkDomain = `/admin/auth/check-domain`;

export const registration = async (data) => {
  try {
    const response = await axios.post(REGISTRATION, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return { message: "Something went wrong" };
  }
};

export const getAllTrustType = async () => {
  const response = await axios.post(trustTypes);
  return response?.data?.data;
};
export const getAllCityState = async () => {
  const response = await axios.get(cityState);
  return response?.data?.data;
};

export const CheckDomain = async (data) => {
  try {
    const response = await axios.post(checkDomain, data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return { message: "Something went wrong" };
  }
};

import { useQuery } from "@tanstack/react-query";
import he from "he";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import "animate.css";
import bg_home from "../../assets/images/background/bg_home.png";
import placeholderImage from "../../assets/images/background/login.svg";
import uploadedPdfIcon from "../../assets/images/icons/iconUploadedPdf.svg";
import { getTrustDetail } from "../api/trustDetailApi";
import { ConvertFirstLatterToCapital } from "../registerNow";

const IntroWrap = styled.div`
  .bg_background_intro {
    width: 100%;
    height: 70vh;
    min-height: 200px;
    background-color: #fff7e8;
    /* background-image: url(${bg_home});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover; */
    border-radius: 10px;
  }
  .details_title {
    color: var(--unnamed-color-583703);
    /* text-align: center; */
    font: normal normal 900 35px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
    padding-top: 1rem;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    /* text-align: center; */
    font: normal normal 500 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .location_title {
    font: normal normal 500 16px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .heading_div {
    color: var(--unnamed-color-583703);
    font: normal normal 900 32px/50px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
    padding-bottom: 1rem;
  }
  .div_size {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .trust_img {
    /* border: 1px solid black; */
    border-radius: 10px;
    overflow: hidden;
  }
  .trust_img img {
    width: 100%;
    object-fit: cover;
    object-position: top center;
    height: 180px;
  }
  .temple_name {
    font: normal normal bold 20px/38px Noto Sans;
    color: #583703;
  }
  .temple_time {
    font: normal normal 400 16px/13px Noto Sans;
    color: #9c9c9c;
  }
  .temple_location {
    color: var(--unnamed-color-583703);
    font: normal normal 500 18px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .docFileName {
    color: #583703;
  }
  .imagesScroller::-webkit-scrollbar {
    display: block;
    height: 5px;
  }
  .imagesScroller::-webkit-scrollbar-track {
    background: #ffd6bf;
    border-radius: 10px;
  }
  .imagesScroller::-webkit-scrollbar-thumb {
    background: #ff8744;
    border-radius: 10px;
  }
  @media screen and (max-width: 1199px) {
    .temple_name {
      font: normal normal bold 16px/38px Noto Sans;
    }
    .temple_time {
      font: normal normal 400 14px/13px Noto Sans;
      color: #9c9c9c;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/30px Noto Sans;
    }
    .temple_location {
      font: normal normal 500 15px/30px Noto Sans;
    }
    .location_title {
      font: normal normal 500 12px/30px Noto Sans;
    }
    .details_title {
      font: normal normal 900 26px/48px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 22px/20px Noto Sans;
    }
    .temple_name {
      font: normal normal bold 16px/38px Noto Sans;
    }
    .temple_time {
      font: normal normal 400 14px/13px Noto Sans;
      color: #9c9c9c;
    }

    .bg_background_intro {
      height: 180px;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/30px Noto Sans;
    }
    .temple_location {
      font: normal normal 500 14px/30px Noto Sans;
    }
    .location_title {
      font: normal normal 500 11px/30px Noto Sans;
    }
    .details_title {
      font: normal normal 900 26px/48px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 22px/20px Noto Sans;
    }
    .div_size {
      padding-top: 1rem;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/30px Noto Sans;
    }
    .temple_location {
      font: normal normal 500 13px/30px Noto Sans;
    }
    .location_title {
      font: normal normal 500 11px/30px Noto Sans;
    }
    .details_title {
      font: normal normal 900 20px/26px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 18px/20px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .temple_location {
      font: normal normal 500 9px/30px Noto Sans;
    }
    .location_title {
      font: normal normal 500 8px/30px Noto Sans;
    }
    .details_title {
      font: normal normal 900 18px/26px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 16px/20px Noto Sans;
      padding-bottom: 10px;
    }
  }
`;

export default function Intro() {
  const { trustId } = useParams();

  const trustDetailQuery = useQuery(["trustTypes", trustId], () =>
    getTrustDetail(trustId)
  );

  const trustDetails = useMemo(
    () => trustDetailQuery?.data?.result ?? [],
    [trustDetailQuery]
  );
  return (
    <IntroWrap>
      <Container fluid style={{ paddingTop: "6rem" }}>
        <Container>
          <div className="bg_background_intro">
            <img
              src={
                trustDetails?.profilePhoto?.length
                  ? trustDetails?.profilePhoto
                  : placeholderImage
              }
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                objectPosition:
                  trustDetails?.profilePhoto?.length && "top center",
                objectFit: trustDetails?.profilePhoto?.length && "cover",
              }}
              alt="Temple Profile"
            />
          </div>
          <div>
            <div className="details_title">
              {ConvertFirstLatterToCapital(trustDetails?.name ?? "")}
            </div>
            <div className="row align-items-center">
              <Col lg={12} xs={8}>
                <div className="temple_location">
                  {ConvertFirstLatterToCapital(trustDetails?.location ?? "")}
                </div>
              </Col>
            </div>
            <div className="div_size pb-0">
              <div className="heading_div">About Trust</div>
              <div className="bg_sub_title">
                <div
                  dangerouslySetInnerHTML={{
                    __html: he.decode(trustDetails?.about ?? ""),
                  }}
                />
              </div>
            </div>
            {trustDetails?.images?.length !== 0 ? (
              <div className="div_size pb-0">
                <div className="heading_div">Images</div>
                <Row className="flex-nowrap overflow-auto imagesScroller">
                  {trustDetails?.images?.map((item, idx) => {
                    return (
                      <Col xs={6} sm={3} key={idx}>
                        <div className="trust_img mt-4 mb-2">
                          <img
                            src={item?.presignedUrl}
                            alt="Trust Images"
                            style={{ cursor: "zoom-in" }}
                            onClick={() =>
                              Swal.fire({
                                imageUrl: item?.presignedUrl,
                                // "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg",
                                padding: 0,
                                imageAlt: "Trust Images",
                                imageHeight: 350,
                                imageWidth: 1000,
                                customClass: {
                                  image: "popup-trust-images", // Add a custom class to the image container
                                },
                                showConfirmButton: false,
                                showCloseButton: true,
                                showClass: {
                                  popup:
                                    "animate__animated animate__zoomIn animate__faster",
                                },
                                hideClass: {
                                  popup:
                                    "animate__animated animate__zoomOut animate__faster",
                                },
                              })
                            }
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              ""
            )}
            {/* trust Documents */}
            {trustDetails?.documents?.length !== 0 ? (
              <div className="div_size pb-0">
                <div className="heading_div">Documents</div>
                <Row className="">
                  {trustDetails?.documents?.map((item, idx) => {
                    return (
                      <Col
                        lg={2}
                        md={4}
                        sm={6}
                        key={idx}
                        className="text-center"
                      >
                        <a
                          href={item?.presignedUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <div className="">
                            <img src={uploadedPdfIcon} width={50} alt="" />
                          </div>
                          <div className="docFileName mt-2">{item?.name}</div>
                        </a>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              ""
            )}
            {trustDetails?.facilities?.length !== 0 ? (
              <div className="div_size">
                <div className="heading_div">Trust Facilities</div>
                <Row>
                  {trustDetails?.facilities?.map((item, idx) => {
                    return (
                      <Col lg={3} md={4} sm={6} key={idx}>
                        <div className="trust_img">
                          <img src={item?.image} alt="" />
                        </div>
                        <div className="py-1">
                          <div className="temple_name">
                            {ConvertFirstLatterToCapital(item?.name)}
                          </div>
                          <div className="temple_time">
                            Timings : {item?.startTime} to {item?.endTime}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              ""
            )}
          </div>
        </Container>
      </Container>
    </IntroWrap>
  );
}

import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { getAllTemples } from "../api/templeApi";

const WrapSearchBar = styled.div`
  .searchInput {
    background: #f2f2f2 !important ;
    &.sInput {
      /* border-end-end-radius: 0% !important ;
      border-start-end-radius: 0% !important ; */
      font: normal normal 400 16px/20px noto sans;
    }
    &.sIconsBox {
      /* border-start-start-radius: 0% !important;
      border-end-start-radius: 0% !important; */
      cursor: pointer;
    }
  }
  .list-unstyled {
    max-height: 300px;
    overflow: auto;
  }
  .list-unstyled::-webkit-scrollbar {
    width: 5px;
  }
  .list-unstyled::-webkit-scrollbar-thumb {
    background: #ff8744;
    border-radius: 10px;
  }
  .searchInput::placeholder {
    font: normal normal 600 16px/20px noto sans;
    color: #c4c4c4;
  }
  img {
    width: 35px;
    /* margin: 10px; */
  }
  input:focus {
    box-shadow: none;
  }
  .searchBox {
    background: #f2f2f2 !important ;
  }
  .searchList {
    cursor: pointer;
  }
  ul > li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 1rem;
  }
  ul > li:hover {
    background-color: #ff8744;
    color: #fff;
  }
  .noTrustFound {
    text-align: center;
    font: normal normal 600 16px/20px noto sans;
    color: #c4c4c4;
    padding: 1rem 0rem;
  }
`;

export default function SearchBar() {
  const navigate = useNavigate();
  const [isSearchable, setIsSearchable] = useState();
  const [searchDebounce] = useDebounce(isSearchable, 1000);
  const searchTemple = useQuery(
    ["temples", searchDebounce],
    () => isSearchable?.length > 2 && getAllTemples({ search: isSearchable })
  );
  const templeList = useMemo(() => searchTemple?.data?.results, [searchTemple]);

  return (
    <WrapSearchBar>
      <InputGroup className=" w-100 h-75 searchInput rounded d-flex align-items-center position-relative ">
        <Input
          className=" sInput searchInput border-0 h-7  "
          placeholder="Search Temple, Trust, etc"
          value={isSearchable}
          onChange={(e) => {
            // if (e.target.value?.length > 2) {
            setIsSearchable(e.target.value);
            // }
          }}
        />
        <InputGroupText className="sIconsBox searchInput border-0  h-75">
          <Search color="#583703" />
        </InputGroupText>
        {isSearchable?.length > 2 ? (
          <div className="position-absolute top-100 w-100 mt-1 searchBox">
            <ul className="list-unstyled">
              {templeList?.length > 0 ? (
                templeList?.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className="py-1 searchList"
                      onClick={() => {
                        window.location.assign(
                          `https://${item?.subDomain}${process.env.REACT_APP_DOMAIN_NAME}`
                        );
                        window.scroll(0, 0);
                        setIsSearchable("");
                      }}
                    >
                      <span className="">{item?.name}</span>
                    </li>
                  );
                })
              ) : (
                <div className="noTrustFound">No Trust Found</div>
              )}
            </ul>
          </div>
        ) : null}
      </InputGroup>
    </WrapSearchBar>
  );
}

import React from "react";
import Helmet from "react-helmet";
import AboutUs from "../../component/aboutUS/index";
import ContactUs from "../../component/contactUs";
import DownloadSection from "../../component/downloadSection";
import Footer from "../../component/footer";
import Navigation from "../../component/navigation";
import Registration from "../../component/registration";
import ProductServices from "../../component/productservices";
import Pricing from "../../component/pricing";

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apna Dharm| Home</title>
      </Helmet>
      <div id="/">
        <Navigation className="navPosition" />
        <div id="home">
          <DownloadSection />
        </div>
        <div id="about-us">
          <AboutUs />
        </div>
        <div id="registration">
          <Registration />
        </div>
        <div id="productservices">
          <ProductServices />
        </div>
        <div id="pricing">
          <Pricing />
        </div>
        <div id="contact-us">
          <ContactUs />
        </div>
        <Footer />
      </div>
    </>
  );
}

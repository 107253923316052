import React from "react";
import bg_home from "../../assets/images/background/bg_home.png";
import apple_icon from "../../assets/images/icons/apple_icon.png";
import play_store_icon from "../../assets/images/icons/play_store_icon.png";
import styled from "styled-components";
import { Container } from "reactstrap";

const WrapDownloadSection = styled.div`
  .download_bg {
    width: 100%;
    height: 100vh;
    min-height: 500px;
    background-image: url(${bg_home});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
  }
  .bg_title {
    color: var(--unnamed-color-583703);
    font: normal normal 900 60px/102px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .icon_width img {
    cursor: pointer;
    width: 100%;
  }
  .icon_width {
    width: 200px;
    margin-left: 10px;
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .bg_title {
      font: normal normal 900 34px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .bg_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .bg_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .bg_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
  }
`;

export default function DownloadSection() {
  return (
    <WrapDownloadSection>
      <div
        className="container-fluid download_bg"
        style={{ paddingTop: "13rem" }}
      >
        <Container className="d-flex flex-column justify-content-center h-100 text-center w-75 w-100">
          <div className="bg_title">DOWNLOAD THE APP NOW !</div>
          <div className="bg_sub_title">
            Apnadharm is a Temple Resource Planning Platform (ERP for Temples),
            we offers a comprehensive end-to-end digitized solution for
            religious trusts, including temples, trusts, gaushalas,
            aushdhalayas, and NGOs. We also provide all in one app for their
            devotees. We manage donations in compliance with 80G regulations,
            handle pledges, expenses, and accounting to maintain financial
            health. Additionally, we enable temples to stay connected with
            devotees by managing events, noticeboards, and news digitally,
            ensuring seamless communication and engagement within the community.
            No more wastage of paper or cash deposit. Donate with click of a
            button without physically visiting or depositing cash.
          </div>
          <div className="mt-5 d-flex justify-content-center">
            <div className="icon_width">
              <a
                href="https://play.google.com/store/apps/details?id=com.apnamandir"
                target="_blank"
                rel="noreferrer"
              >
                <img src={play_store_icon} alt="Play store" />
              </a>
            </div>
            {
              <div className="icon_width">
                <a
                  href="https://apps.apple.com/tn/app/apna-dharm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={apple_icon} alt="App store" />
                </a>
              </div>
            }
          </div>
        </Container>
      </div>
    </WrapDownloadSection>
  );
}

import React from "react";
import Nav from "./Nav";

export default function Navigation() {
  return (
    <>
      <Nav />
    </>
  );
}

import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const CONTACT = `/admin/public/contact`;


export const contactUs = async (data) => {
    try {
      const response = await axios.post(CONTACT, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return error.response.data;
      }
      return { message: "Something went wrong" };
    }
  };
import React from "react";
import Footer from "../../component/footer";
import Navigation from "../../component/navigation";
import Intros from "../../component/intro";
import { Helmet } from "react-helmet";
export default function Intro() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apna Dharm| Intro</title>
      </Helmet>
      <Navigation />
      <Intros />
      <div className="mt-4">
        <Footer />
      </div>
    </div>
  );
}

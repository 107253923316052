import { Storage } from "aws-amplify";
import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import * as yup from "yup";
import arrowLeft from "../../assets/images/icons/arrow-left.svg";
import { addFacility } from "../../redux/facilitySlice";
import CustomTextField from "../fields/customTextField";
import { TextArea } from "../registerNow";

const WrapFacility = styled.div`
  .details_title {
    color: var(--unnamed-color-583703);
    /* text-align: center; */
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    /* text-align: center; */
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .bg_submit {
    font: normal normal bold 18px/26px Noto Sans;
    background: var(--unnamed-color-ff8744) 0% 0% no-repeat padding-box;
    background: #ff8744 0% 0% no-repeat padding-box;
    /* border-radius: 30px; */
    border: none;
    /* padding: 5px 25px; */
    width: 200px;
    height: 40px;
  }
  .upload_btn {
    width: 200px;
    color: white;
    text-align: center;
    cursor: pointer;
    padding: 7px 10px;
    font: normal normal bold 18px/32px Noto Sans;
    background: #ff8744;
    border-radius: 5px;
    border: none;
  }
  .upload_btn:active,
  .upload_btn:focus-visible {
    background: #ff8744;
    box-shadow: none;
  }
  input[type="file"]::file-selector-button {
    display: none;
  }
  label {
    color: #583703;
    font: normal normal 800 18px/64px Noto Sans;
  }
  .fileInput {
    color: #583703 !important;
    border: none !important;
    background-color: #f8f8f8 !important;
    font: normal normal 600 18px/38px Noto Sans;
  }
  /* time picker css */
  .react-time-picker__wrapper {
    border: none !important;
  }
  .react-time-picker {
    width: 100%;
  }
  .react-time-picker__inputGroup {
    color: #583703 !important;
    border: none !important;
    background-color: #f8f8f8 !important;
    padding: 0rem 0.8rem !important;
    border-radius: 5px;
    line-height: 30px;
    /* font: normal normal normal 13px/24px Noto Sans; */
    font: normal normal 600 18px/38px Noto Sans;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: #f8f8f8 !important;
  }

  input:focus {
    outline: none !important;
  }
  input:focus-visible {
    outline-offset: none;
    outline: none;
  }
`;

export default function Facility({ editImage, defaultImages, thumbnailImage }) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const uploadTemplate = useRef();

  const handleSubmit = (trustFacilities) => {
    dispatch(addFacility(trustFacilities));
    history(-1);
  };
  const randomNumber = Math.floor(100000000000 + Math.random() * 900000000000);

  const [files, setFiles] = useState([]);

  const handleUpload = (acceptedFiles, formik) => {
    const specificDate = new Date();
    const unixTimestampSeconds = Math.floor(specificDate.getTime() / 1000);
    const extension = acceptedFiles?.name.split(".").pop();

    Storage.put(
      `temp/FacilitiesImage_${randomNumber}_${unixTimestampSeconds}.${extension}`,
      acceptedFiles,
      {
        contentType: acceptedFiles.type,
      }
    )
      .then((res) => {
        formik.setFieldValue(
          "image",
          `FacilitiesImage_${randomNumber}_${unixTimestampSeconds}.${extension}`
        );
        formik.setFieldValue("facilityImagePreview", acceptedFiles);
        // props.fileName(acceptedFiles.name,acceptedFiles.type);
        setFiles(
          // acceptedFiles.map((file) =>
          Object.assign(acceptedFiles, {
            preview: URL.createObjectURL(acceptedFiles),
          })
          // )
        );
      })
      .catch((err) => console.log(err));
  };

  const facilitiesValidation = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
  });
  const [selectedTimeStart, setSelectedTimeStart] = useState("");
  const [selectedTimeEnd, setSelectedTimeEnd] = useState("");

  const handleTimeChange = (time) => {
    setSelectedTimeStart(time);
  };
  const handleTimeChangeEnd = (time) => {
    setSelectedTimeEnd(time);
  };

  return (
    <WrapFacility>
      <Container fluid style={{ paddingTop: "3rem" }}>
        <Container>
          <div className="bg_size">
            <Formik
              initialValues={{
                name: "",
                description: "",
                image: "",
                facilityImagePreview: files,
                startTime: "",
                endTime: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={facilitiesValidation}
            >
              {(formik) => {
                return (
                  <Form>
                    <div>
                      <img
                        src={arrowLeft}
                        alt=""
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={() => history(-1)}
                      />
                    </div>
                    <Row>
                      <Col md={6}>
                        <CustomTextField
                          label="Facility Name*"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 30))
                          }
                          placeholder="Enter name "
                          name="name"
                        />
                      </Col>
                      <Col md={6}>
                        <label>Upload Image</label>
                        <div className="d-flex justify-content-between">
                          <input
                            ref={uploadTemplate}
                            type={"file"}
                            name="image"
                            onChange={(e) => {
                              handleUpload(e.target.files[0], formik);
                            }}
                          />
                          <Button
                            className="upload_btn"
                            onClick={() => uploadTemplate.current.click()}
                          >
                            Browse
                          </Button>
                        </div>
                      </Col>

                      <Col xs={12}>
                        <TextArea
                          label="Facility Description"
                          requiredF
                          name="description"
                          rows="6"
                          placeholder="Add Description Here"
                          className="text-area form-control"
                        />
                      </Col>

                      <Col md={6}>
                        <Row>
                          <Col sm={6} md={4}>
                            <label>Start Time</label>
                            <div>
                              <TimePicker
                                onChange={(e) => {
                                  handleTimeChange(e);
                                  formik.setFieldValue("startTime", e);
                                }}
                                name="startTime"
                                value={selectedTimeStart}
                                disableClock={true}
                                clearIcon={null}
                                format="HH:mm"
                                placeholder="HH:mm"
                              />
                            </div>
                          </Col>
                          <Col sm={6} md={4}>
                            <>
                              <label>End Time</label>
                              <TimePicker
                                onChange={(e) => {
                                  handleTimeChangeEnd(e);
                                  formik.setFieldValue("endTime", e);
                                }}
                                name="endTime"
                                value={selectedTimeEnd}
                                disableClock={true}
                                clearIcon={null}
                                format="HH:mm"
                                placeholder="HH:mm"
                              />
                            </>
                          </Col>
                        </Row>
                      </Col>
                      <div className="mt-5">
                        <Button className="bg_submit" type="submit">
                          Add Facility
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Container>
      </Container>
    </WrapFacility>
  );
}

import { Amplify } from "aws-amplify";

export default function configureAmplify() {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_POOL_ID,
      region: process.env.REACT_APP_REGION,
    },
    Storage: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_POOL_ID,
    },
  });
}

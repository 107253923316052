import { Form, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";
import { contactUs } from "../api/contactUsApi";
import CustomTextField from "../fields/customTextField";
import { TextArea } from "../registerNow";

const WrapContactUs = styled.div`
  .details_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .bg_submit {
    font: normal normal bold 18px/26px Noto Sans;
    background: var(--unnamed-color-ff8744) 0% 0% no-repeat padding-box;
    background: #ff8744 0% 0% no-repeat padding-box;
    border-radius: 30px;
    border: none;
    /* padding: 5px 25px; */
    width: 200px;
    height: 40px;
  }
  .bg_submit:active {
    background-color: #ff8744;
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 35px/57px Noto Sans;
    }
    .bg_size {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .details_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
  }
`;

export default function ContactUs() {
  const contactFormSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    description: yup.string().required("Description is required"),
  });
  return (
    <WrapContactUs>
      <Container fluid style={{ paddingTop: "2rem" }}>
        <Container className="bg_size">
          <div className="details_title">CONTACT US</div>
          <Formik
            enableReinitialize
            initialValues={{
              name: "",
              email: "",
              subject: "",
              description: "",
            }}
            onSubmit={async (e, { resetForm }) => {
              const data = {
                name: e?.name,
                email: e?.email,
                subject: e?.subject,
                description: e?.description,
              };
              const responseData = await contactUs(data);
              if (responseData) {
                if (responseData.code === 201 || responseData.code === 200) {
                  Swal.fire({
                    icon: "success",
                    html: `<h5>
                      Thank you for contacting us. We have send a mail for your query.</h5>`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  resetForm();
                } else {
                  toast.error(responseData.message);
                }
              } else {
                toast.error("Something went wrong");
              }
            }}
            validationSchema={contactFormSchema}
          >
            {({ values }) => {
              return (
                <Form>
                  <Row>
                    <Col md={6}>
                      <CustomTextField
                        label="Your Name"
                        name="name"
                        placeholder="Enter your name here"
                        requiredF
                      />
                    </Col>
                    <Col md={6}>
                      <CustomTextField
                        label="Your Email"
                        name="email"
                        requiredF
                        type="email"
                        placeholder="Enter your email here"
                      />
                    </Col>
                    <Col xs={12}>
                      <CustomTextField
                        label="Your Subject"
                        name="subject"
                        placeholder="Enter your subject here"
                      />
                    </Col>
                    <Col xs={12}>
                      <TextArea
                        label="Your Description"
                        requiredF
                        name="description"
                        rows="6"
                        placeholder="Enter your description here"
                        className="text-area form-control"
                      />
                    </Col>
                    <div className="text-center mt-2">
                      <Button className="bg_submit" type="submit">
                        Submit
                      </Button>
                    </div>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Container>
    </WrapContactUs>
  );
}

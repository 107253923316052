import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

const WrapDetails = styled.div`
  .details_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    margin-bottom: 1rem;
    color: #583703;
    text-transform: uppercase;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 35px/57px Noto Sans;
    }
    .bg_size {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .details_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
  }
`;

export default function AboutUs() {
  return (
    <WrapDetails>
      <Container fluid style={{ paddingTop: "2rem" }}>
        <Container className="bg_size">
          <div className="text-center">
            <div className="details_title">WHO WE ARE</div>
            <div className="bg_sub_title pb-4">
              Being donors ourselves, we observed the inefficiency on handling
              the donations done by us using various sources. This becomes a
              mammoth task of data cleaning and book keeping to map correct
              donor with online transaction to provide income tax benefits. With
              this problem statement in mind, we started building Apna Dharm App
            </div>
            <div className="bg_sub_title">
              We are a team with cumulatively experience of 25+ years on
              software technologies, digital marketing and digital content
              creation and management. We are very passionate about solving this
              problem for the community.
            </div>
          </div>
        </Container>
      </Container>
    </WrapDetails>
  );
}

import React from "react";
import Footer from "../../component/footer";
import Navigation from "../../component/navigation";
import Facility from "../../component/facility";
import { Helmet } from "react-helmet";

export default function Facilities() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apna Dharm| Facilities</title>
      </Helmet>
      <Navigation />
      <Facility />
      <Footer />
    </div>
  );
}

import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Input } from "reactstrap";
import styled from "styled-components";

const LocationWrapper = styled.div`
  input::placeholder {
    opacity: 0.5;
    color: #c4c4c4;
    font: normal normal 600 18px/38px Noto Sans;
  }
  input {
    color: #583703 !important;
    border: none !important;
    background-color: #f8f8f8 !important;
    font: normal normal 600 18px/38px Noto Sans;
    border-radius: 5px;
  }
  /* map css */
  .autocomplete-dropdown-container {
    border: none;
    color: #583703 !important;
    background-color: #f8f8f8 !important;
    z-index: 100;
    border-radius: 3px;
    margin-top: 2px;
    width: 100%;
    max-height: 150px;
    overflow-y: scroll !important;
    overflow-x: hidden;
    position: absolute;
    /* padding: 1rem; */
    font: normal normal bold 18px/28px noto sans;
    box-shadow: 2px 4px 14px 0 rgba(34, 41, 47, 0.4);
    ::focus {
      background-color: #fff7e8 !important;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #583703 !important;
      border-radius: 5px;
      height: 50px;
      width: 10px !important;
    }
  }
  .suggestion-item {
    border: none;
    background-color: #f8f8f8 !important;
    box-shadow: none;
    word-wrap: break-word;
    :hover {
      border: none !important;
    }
  }
`;

const CustomLocationField = (props) => {
  console.log("props", props);
  const handleChange = (address) => {
    console.log("address", address);
    props.setFieldValue("location", address);
  };

  const getCity = (addressArray) => {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types.includes("postal_town")) {
        return addressArray[i].long_name;
      } else if (
        addressArray[i].types.includes("administrative_area_level_3")
      ) {
        return addressArray[i].long_name;
      } else if (
        addressArray[i].types.includes("administrative_area_level_2")
      ) {
        return addressArray[i].long_name;
      }
    }
    return "";
  };
  const getState = (addressArray) => {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types.includes("administrative_area_level_1")) {
        return addressArray[i].long_name;
      }
    }
    return "";
  };

  const handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      console.log("results", results);
      console.log(
        getCity(results[0].address_components),
        getState(results[0].address_components)
      );

      getLatLng(results[0])
        .then((latLng) => {
          props.setFieldValue("location", results[0].formatted_address);
          props.setFieldValue(
            "city",
            getCity(results[0].address_components) || ""
          );
          props.setFieldValue(
            "state",
            getState(results[0].address_components) || ""
          );

          props.setFieldValue("longitude", latLng.lat);
          props.setFieldValue("latitude", latLng.lng);
        })
        .catch((error) => console.error("Error", error));
    });
  };

  return (
    <LocationWrapper>
      <PlacesAutocomplete
        value={props?.values?.location}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          strictbounds: ["address"],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="locationField w-100 position-relative">
            <Input
              className="w-100"
              {...getInputProps({
                placeholder: "Search Places ...",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions?.map((suggestion) => {
                const className = suggestion?.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#ff8744",
                      color: "#fff",
                      padding: "5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      borderRadius: "3px",
                      padding: "5px",
                      cursor: "pointer",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion?.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </LocationWrapper>
  );
};

export default CustomLocationField;

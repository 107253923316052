import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { getAllTrustType } from "../api/registrationApi";

const WrapperRegistration = styled.div`
  .details_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    margin-bottom: 1rem;
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .pricing_box {
    background: #f8f8f8;
    box-shadow: 0px 0px 20px #00000033;
    border-radius: 20px;
    border: none;
    height: 170px;
    cursor: pointer;
  }
  .pricing_box:hover {
    background-color: #ff8744;
  }
  .pricing_box:hover .pricing-as {
    color: #f8f8f8;
  }
  .pricing_box:hover .pricing-temp {
    color: #f8f8f8;
  }
  .pricing_box .card-body {
    display: flex;
    flex-direction: column;
    justify-content: top;
    padding: 25px;
  }
  .pricing-as {
    color: var(--unnamed-color-583703);
    text-align: center;
    font: normal normal bold 24px/32px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    margin-top: 10px;
  }
  .pricing-temp {
    color: var(--unnamed-color-ff8744);
    text-align: center;
    font: normal normal 900 30px/30px Noto Sans;
    letter-spacing: 0px;
    color: #ff8744;
  }
  a {
    text-decoration: none;
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
    .pricing-as {
      font: normal normal bold 18px/35px Noto Sans;
    }
    .pricing-temp {
      font: normal normal 900 18px/30px Noto Sans;
    }
    .pricing_box {
      height: 200px;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 35px/57px Noto Sans;
    }
    .bg_size {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .pricing_box {
      height: 130px;
    }
    .pricing-as {
      font: normal normal bold 16px/24px Noto Sans;
    }
    .pricing-temp {
      font: normal normal 900 18px/25px Noto Sans;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .details_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
  }
  @media screen and (max-width: 377px) {
    .pricing-as {
      font: normal normal bold 12px/18px Noto Sans;
    }
    .pricing-temp {
      font: normal normal 900 16px/25px Noto Sans;
    }
  }
`;

export default function Pricing() {
  function ConvertFirstLatterToCapital(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const trustType = [
    {
      name: "Basic",
      description: `₹2100 per month
      Upto 1000 transaction`,
    },
    {
      name: "Advanced",
      description: `₹3100 per month
      Upto 5000 transaction`,
    },
    {
      name: "Custom",
      description: `₹1.5 per transactions
      For 5000+ transactions`,
    },
  ];

  return (
    <WrapperRegistration>
      <Container fluid style={{ paddingTop: "1rem" }}>
        <Container className="bg_size">
          <div className="text-center">
            <div className="details_title">Pricing</div>
            <div className="bg_sub_title pb-4">
              Subscription fee will be calculated according to the number of
              donations received by any Trusts.
            </div>
          </div>
          <Row className="row-cols-4">
            {trustType?.map((item, index) => {
              return (
                <Col
                  md={4}
                  sm={6}
                  xs={12}
                  key={index}
                  className="mt-4 col-12"
                  onClick={() => {}}
                >
                  <Card className="pricing_box">
                    <CardBody>
                      <div className="pricing-temp">{item?.name}</div>
                      <div className="pricing-as">
                        {item?.description.split("\\").map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </WrapperRegistration>
  );
}

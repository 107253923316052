import React from "react";
import { Container } from "reactstrap";

const RefundPolicy = () => {
  return (
    <Container>
      <center>
        <h1 style={{ margin: "2rem 0" }}>Cancellation & Refund Policy</h1>
      </center>
      <p>
        DHARM PRABHAVNA TECHNOLOGIES LLP believes in helping its customers as
        far as possible, and has therefore a liberal cancellation policy. Under
        this policy:
      </p>
      <li>
        Cancellations will be considered only if the request is made immediately
        after placing the order. However, the cancellation request may not be
        entertained if the orders have been communicated to the
        vendors/merchants and they have initiated the process of shipping them.
      </li>
      <li>
        DHARM PRABHAVNA TECHNOLOGIES LLP does not accept cancellation requests
        for perishable items like flowers, eatables etc. However,
        refund/replacement can be made if the customer establishes that the
        quality of product delivered is not good.
      </li>
      <li>
        In case of receipt of damaged or defective items please report the same
        to our Customer Service team. The request will, however, be entertained
        once the merchant has checked and determined the same at his own end.
        This should be reported within 30 Days days of receipt of the products.
        In case you feel that the product received is not as shown on the site
        or as per your expectations, you must bring it to the notice of our
        customer service within 30 Days days of receiving the product. The
        Customer Service Team after looking into your complaint will take an
        appropriate decision.
      </li>
      <li>
        In case of complaints regarding products that come with a warranty from
        manufacturers, please refer the issue to them. In case of any Refunds
        approved by the DHARM PRABHAVNA TECHNOLOGIES LLP, it’ll take 9-15 Days
        days for the refund to be processed to the end customer.
      </li>
    </Container>
  );
};

export default RefundPolicy;

import { useField } from "formik";
import React, { useState } from "react";
import { Info } from "react-feather";
import { FormGroup, InputGroup, Tooltip } from "reactstrap";
import styled from "styled-components";

const CustomTextFieldWarper = styled.div`
  color: #583703 !important;
  font: normal normal bold 11px/33px Noto Sans;

  .formGroup {
    width: ${(props) => props.width ?? "auto"};
    margin: ${(props) => props.margin ?? "auto"} !important;
  }
  label {
    /* font: normal normal bold 15px/33px Noto Sans; */
    font: normal normal 800 18px/64px Noto Sans;
  }
  .helperText {
    font: normal normal 600 14px/64px Noto Sans;
    color: gray;
  }
  input {
    color: #583703 !important;
    border: none !important;
    background-color: #f8f8f8 !important;
    /* font: normal normal normal 13px/24px Noto Sans; */
    font: normal normal 600 18px/38px Noto Sans;
  }
  input:focus {
    box-shadow: none;
  }
  input[type="file"]::file-selector-button {
    display: none;
  }

  input::-webkit-input-placeholder {
    opacity: 0.5;
    color: #c4c4c4;
  }
`;

export default function CustomTextField({
  requiredF = false,
  label,
  type = "text",
  icon,
  showHelperText = true,
  placeholder,
  roundDigits,
  reference,
  width,
  helperText,
  ...props
}) {
  const [field, meta, helpers] = useField(props);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <CustomTextFieldWarper width={width}>
      <FormGroup className="formGroup">
        {label && (
          <label>
            {`${label} `}
            {requiredF && "*"}
            {helperText && (
              <span className="helperText">
                (Do not add "-admin" in your trust domain)
                {/* <Info id="subdomainHelper" size="20px" className="ms-2" />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="subdomainHelper"
                  toggle={toggle}
                >
                  Do not add "-admin" in your trust domain.
                </Tooltip> */}
              </span>
            )}
          </label>
        )}
        <InputGroup>
          <input
            ref={reference}
            type={type}
            className="form-control"
            placeholder={placeholder}
            {...field}
            {...props}
          />
        </InputGroup>
        {showHelperText && (
          <div style={{ height: "20px" }}>
            {meta?.error && meta?.touched && (
              <div className="text-danger">{meta.error}</div>
            )}
          </div>
        )}
      </FormGroup>
    </CustomTextFieldWarper>
  );
}

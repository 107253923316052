import { useQuery } from "@tanstack/react-query";
import { Storage } from "aws-amplify";
import { Form, Formik, useField } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CheckCircle, Plus, X, XCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";
import bg_plus from "../../assets/images/icons/Thumbnail.svg";
import pdfIcon from "../../assets/images/icons/iconPDF.svg";
import {
  clearFacilities,
  clearHandleRegister,
  handleRegister,
  removeFacility,
} from "../../redux/facilitySlice";
import CustomLocationField from "../CustomLocationField";
import { CheckDomain, registration } from "../api/registrationApi";
import CustomCountryMobileNumberField from "../fields/CustomCountryMobileNumberField";
import CustomTextField from "../fields/customTextField";
import Dropzone from "../imageUpload";

const WrapperRegisterNow = styled.div`
  label {
    color: #583703;
    font: normal normal 800 18px/64px Noto Sans;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  .details_title {
    color: var(--unnamed-color-583703);
    /* text-align: center; */
    font: normal normal 900 42px/57px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
  }
  .bg_sub_title {
    color: var(--unnamed-color-583703);
    /* text-align: center; */
    font: normal normal 600 20px/30px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
  }
  .bg_size {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  .bg_submit {
    font: normal normal bold 18px/26px Noto Sans;
    background: var(--unnamed-color-ff8744) 0% 0% no-repeat padding-box;
    background: #ff8744 0% 0% no-repeat padding-box;
    /* border-radius: 30px; */
    border: none;
    /* padding: 5px 25px; */
    width: 200px;
    height: 40px;
  }
  .heading_div {
    color: var(--unnamed-color-583703);
    font: normal normal 900 32px/50px Noto Sans;
    letter-spacing: 0px;
    color: #583703;
    text-transform: uppercase;
    /* border-bottom: 5px solid; */
  }
  .heading_div hr {
    border: 3px solid #583703;
    opacity: 1;
    border-radius: 10px;
  }
  .facility_button {
    width: 90%;
    /* height: 170px; */
    border: 1px solid #583703;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    padding: 2rem 2rem;
  }
  .facility_button svg {
    width: 100px;
    height: 100px;
    color: #583703;
  }
  .facility_add {
    font: normal normal 800 18px/64px Noto Sans;
    color: #583703;
    text-decoration: none;
  }
  a {
    color: #583703;
    text-decoration: none;
  }
  .trust_img {
    overflow: hidden;
    height: 163px;
  }
  .trust_img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  .temple_name {
    font: normal normal bold 20px/38px Noto Sans;
    color: #583703;
  }
  .temple_time {
    font: normal normal 400 16px/13px Noto Sans;
    color: #9c9c9c;
  }
  .text-area {
    border: none !important;
    color: #583703 !important;
    background-color: #f8f8f8 !important;
    font: normal normal 600 18px/38px Noto Sans;
    width: 100%;
    padding: 0.5rem 0.5rem;
    height: 180px;
    box-shadow: none;
    :focus-visible {
      outline: none;
    }
  }
  .text-area::-webkit-input-placeholder,
  .css-1wa3eu0-placeholder {
    opacity: 0.5;
    color: #c4c4c4;
  }
  .css-1wa3eu0-placeholder {
    opacity: 0.8;
    font: normal normal 600 18px/38px Noto Sans;
  }
  .error {
    font: normal normal bold 11px/33px Noto Sans;
  }

  .css-2b097c-container {
    font-weight: 600;
  }
  .css-1hwfws3 {
    height: 38px;
  }
  .css-yk16xz-control {
    border: none;
    background-color: #f8f8f8 !important;
    ::focus {
      font-weight: 600;
      background-color: #f8f8f8 !important;
    }
  }
  .css-1pahdxg-control {
    border: none;
    background-color: #f8f8f8 !important;
    box-shadow: none;
    font-weight: 600;
    :hover {
      border: none !important;
    }
  }
  .css-ahnfz8 > div > input {
    font-weight: 600;
  }
  input {
    color: #583703 !important;
    border: none !important;
    background-color: #f8f8f8 !important;
    width: 100%;
    padding: 0rem 1rem;
    /* font: normal normal normal 13px/24px Noto Sans; */
    font: normal normal 600 18px/38px Noto Sans;
  }
  input:focus {
    box-shadow: none;
  }
  input[type="file"]::file-selector-button {
    display: none;
  }

  input::-webkit-input-placeholder {
    opacity: 0.5;
    color: #c4c4c4;
  }
  .css-jb8ob8-placeholder {
    padding-left: 0.3rem;
  }
  .removePDFButton {
    position: absolute;
    padding: 0.5rem;
    display: none;
    border: none;
    background-color: transparent !important;
    font: normal normal bold 17px/20px Noto Sans;
    border-color: none !important ;
    color: #ff8744 !important;
    right: 0px;
    top: -15px;
  }
  .facilityCol:hover .removeImageButton {
    display: block;
  }
  .removeImageButton {
    position: absolute;
    padding: 0.5rem;
    display: none;
    border: none;
    background-color: transparent !important;
    font: normal normal bold 17px/20px Noto Sans;
    border-color: none !important ;
    color: #ff8744 !important;
    right: 10px;
    z-index: 10000000;
    top: 20px;
  }
  .pdfDiv {
    text-align: center;
    font: normal normal bold 13px/20px Noto Sans;
  }
  .pdfDiv:hover .removePDFButton {
    display: block;
  }

  @media screen and (max-width: 1199px) {
    .temple_name {
      font: normal normal bold 16px/38px Noto Sans;
    }
    .temple_time {
      font: normal normal 400 14px/13px Noto Sans;
      color: #9c9c9c;
    }
    .facility_add {
      font: normal normal bold 16px/38px Noto Sans;
    }
  }
  @media screen and (max-width: 991px) {
    .bg_title {
      font: normal normal 900 48px/60px Noto Sans;
    }
  }
  @media screen and (max-width: 767px) {
    .bg_sub_title {
      font: normal normal 500 16px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 35px/57px Noto Sans;
    }
    .bg_size {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .heading_div {
      font: normal normal 900 29px/35px Noto Sans;
    }
    .temple_name {
      font: normal normal bold 16px/38px Noto Sans;
    }
    .temple_time {
      font: normal normal 400 14px/13px Noto Sans;
      color: #9c9c9c;
    }
    .facility_add {
      font: normal normal bold 16px/38px Noto Sans;
    }
  }
  @media screen and (max-width: 540px) {
    .bg_sub_title {
      font: normal normal 500 15px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 30px/60px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 27px/35px Noto Sans;
    }
  }
  @media screen and (max-width: 494px) {
    .bg_sub_title {
      font: normal normal 500 14px/23px Noto Sans;
    }
    .details_title {
      font: normal normal 900 24px/60px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 22px/35px Noto Sans;
    }
  }
  @media screen and (max-width: 463px) {
    .bg_sub_title {
      font: normal normal 500 12px/16px Noto Sans;
    }
    .details_title {
      font: normal normal 900 22px/40px Noto Sans;
    }
    .heading_div {
      font: normal normal 900 18px/28px Noto Sans;
    }
  }
`;

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <WrapperRegisterNow>
      <label
        htmlFor={props.id || props.name}
        style={{
          font: "normal normal 800 18px/64px Noto Sans",
        }}
      >
        {label}
        {props.requiredF && "*"}
      </label>
      <textarea className="text-area" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null}
    </WrapperRegisterNow>
  );
};
export { TextArea };

export function ConvertFirstLatterToCapital(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function RegisterNow({
  editImage,
  defaultImages,
  thumbnailImage,
}) {
  const history = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(history.search);

  const currentTrustType = searchParams.get("trust-type-name");
  const currentTrustTypeId = searchParams.get("id");

  // trust register Data
  const trustRegisterData = useSelector((state) => state?.trustRegister);

  // facility
  const facilityData = useSelector(
    (state) => state?.trustRegister?.trustFacilities
  );
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    trustName: yup
      .string()
      .required("Name is required")
      .matches("^[a-z A-Z 0-9]+$", "Name contain only alphanumeric character"),
    trustEmail: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
    trustNumber: yup
      .string()
      .matches(
        /^[^!@$%^*()_+\=[\]{};':"\\|.<>/?`~]*$/,
        "Special characters are not allowed"
      )
      .required("Contact Number is required"),
    name: yup
      .string()
      .matches(
        /^[^!@$%^*()_+\=[\]{};':"\\|.<>/?`~]*$/,
        "Special characters are not allowed"
      )
      .required("Name is required")
      .trim(),

    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required")
      .trim(),
    mobileNumber: yup
      .string()
      .matches(
        /^[^!@$%^*()_+\=[\]{};':"\\|.<>/?`~]*$/g,
        "Special characters are not allowed"
      )
      .required("Mobile Number is required")
      .trim(),
    about: yup
      .string()
      .matches(
        /^[^!@$%^*()_+\=[\]{};':"\\|.<>/?`~]*$/g,
        "Special characters are not allowed"
      )
      .required("Description is required")
      .trim(),
    location: yup.string().required("Location is required").trim(),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    domainName: yup
      .string()
      .required("Domain name is required")
      .matches(
        /^[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*$/,
        "Insert a valid sub domain(eg:abc OR abc-d)"
      )
      .trim(),
  });

  const initialValues = useMemo(() => {
    return {
      trustName: trustRegisterData?.trustName,
      trustEmail: trustRegisterData?.trustEmail,
      trustNumber: trustRegisterData?.trustNumber,
      trustDialCode: trustRegisterData?.trustDialCode ?? "91",
      trustCountryCode: trustRegisterData?.trustCountryCode ?? "in",
      domainName: trustRegisterData?.domainName,
      facebookLink: trustRegisterData?.facebookLink ?? "",
      address: trustRegisterData?.address ?? "",
      name: trustRegisterData?.name,
      email: trustRegisterData?.email,
      mobileNumber: trustRegisterData?.mobileNumber,
      countryCode: trustRegisterData?.countryCode ?? "91",
      dialCode: trustRegisterData?.dialCode ?? "in",
      trustType: ConvertFirstLatterToCapital(currentTrustType),
      about: trustRegisterData?.about,
      city: trustRegisterData?.city,
      state: trustRegisterData?.state,
      location: trustRegisterData?.location,
      longitude: trustRegisterData?.longitude,
      latitude: trustRegisterData?.latitude,
      trustFacilities: trustRegisterData?.trustFacilities,
      images: [],
      documents: [],
    };
  }, [trustRegisterData, currentTrustType]);

  const [imageSpinner, setImageSpinner] = useState(false);
  const [profileImageSpinner, setProfileImageSpinner] = useState(false);

  const randomNumber = Math.floor(100000000000 + Math.random() * 900000000000);
  const [deletedImages, setDeletedImages] = useState([]);
  const uploadDocuments = useRef();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (initialValues?.documents?.length > 0) {
      setFiles(initialValues?.documents);
    }
  }, [initialValues]);

  const [deletedDocuments, setDeletedDocuments] = useState([]);

  const handleUpload = (acceptedFiles, uploadType, formik) => {
    const specificDate = new Date();
    const unixTimestampSeconds = Math.floor(specificDate.getTime() / 1000);
    const extension = acceptedFiles?.name.split(".").pop();

    Storage.put(
      `temp/Documents_${randomNumber}_${unixTimestampSeconds}.${extension}`,
      acceptedFiles,
      {
        contentType: acceptedFiles?.type,
      }
    )
      .then((res) => {
        const uploadedDocumentName = res.key.split("temp/")[1];
        formik.setFieldValue("documents", [
          ...formik.values.documents,
          `Documents_${randomNumber}_${unixTimestampSeconds}.${extension}`,
        ]);
        setFiles([...files, uploadedDocumentName]);
      })
      .catch((err) => console.log(err));
  };

  const removeDocumentFile = (file, formik) => {
    setDeletedDocuments((prev) => [...prev, file]);
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    formik.setFieldValue("documents", newFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "",
    onDrop: (acceptedFiles) => {
      handleUpload(acceptedFiles[0]);
    },
  });

  const [deletedFacility, setDeletedFacility] = useState([]);

  const removeFacilityF = (file, formik) => {
    setDeletedFacility((prev) => [...prev, file]);
    const newFacilitiesFiles = [...formik.values.trustFacilities];
    dispatch(removeFacility(newFacilitiesFiles.indexOf(file)));
    console.log("newFacilitiesFiles", newFacilitiesFiles);
    formik.setFieldValue("trustFacilities", newFacilitiesFiles);
    console.log("item-------->", formik.values);
  };

  const [domainNameState, setDomainNameState] = useState("");

  const checkDomainName = useQuery(
    [domainNameState],
    async () =>
      await CheckDomain({
        subDomain: domainNameState,
      })
  );

  const [phoneNumber, setPhoneNumber] = useState(
    initialValues?.trustDialCode + initialValues?.trustNumber ?? ""
  );
  const [trustMobileNumberState, setTrustMobileNumberState] = useState(
    initialValues?.dialCode + initialValues?.mobileNumber ?? ""
  );

  return (
    <WrapperRegisterNow>
      <Container fluid style={{ paddingTop: "3rem" }}>
        <Container>
          <div className="bg_size">
            <div className="details_title">REGISTER NOW</div>
            <div className="bg_sub_title pb-4">
              All Temples and religious trusts can contact us by filling the
              following form to activate their account. Individual Users can
              register by downloading app from iOS or google play store.
            </div>
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (e) => {
                  const data = {
                    trustName: e?.trustName,
                    trustEmail: e?.trustEmail,
                    trustNumber: e?.trustNumber.toString(),
                    trustCountryName: e?.trustCountryCode,
                    facebookLink: e?.facebookLink,
                    address: e?.address,
                    trustCountryCode: e?.trustDialCode,
                    subDomain: e?.domainName,
                    name: e?.name,
                    email: e?.email,
                    countryName: e?.countryCode,
                    countryCode: e?.dialCode,
                    mobileNumber: e?.mobileNumber.toString(),
                    location: e?.location,
                    longitude: e?.longitude.toString(),
                    latitude: e?.latitude.toString(),
                    images: e?.images,
                    profilePhoto: e?.profileImage,
                    removedImages: deletedImages,
                    documents: e?.documents,
                    typeId: currentTrustTypeId,
                    about: e?.about,
                    city: e?.city,
                    state: e?.state,
                    trustFacilities: facilityData,
                  };
                  const responseData = await registration(data);
                  if (responseData) {
                    if (
                      responseData.code === 201 ||
                      responseData.code === 200
                    ) {
                      Swal.fire({
                        width: 700,
                        position: "center",
                        icon: "success",
                        html: `<h3 style="color:#583703; font: normal normal 900 20px/29px Noto Sans;">
                        Your approval request has been submitted to the admin successfully, you'll be notified once accepted
                        </h3>`,
                        showConfirmButton: true,
                        confirmButtonText: `<div style="">To Webpage</div>`,
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          window.location.assign(
                            `https://${responseData?.data?.result?.subDomain}${process.env.REACT_APP_DOMAIN_NAME}`
                          );
                          window.scroll(0, 0);
                        }
                      });
                    } else {
                      toast.error(responseData.message);
                    }
                  } else {
                    toast.error("Something went wrong");
                  }
                  dispatch(clearFacilities(), clearHandleRegister());
                }}
                validationSchema={schema}
              >
                {(formik) => {
                  return (
                    <Form>
                      <Row>
                        <Col xs={12}>
                          <div className="heading_div">
                            ABOUT
                            <hr />
                          </div>
                        </Col>
                        <Col md={6}>
                          <CustomTextField
                            label="Name"
                            name="trustName"
                            placeholder="Enter name "
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 256))
                            }
                            requiredF
                          />
                        </Col>
                        <Col md={6}>
                          <CustomTextField
                            label="Email"
                            name="trustEmail"
                            placeholder="Enter email "
                            requiredF
                          />
                        </Col>
                        <Col md={6}>
                          <CustomCountryMobileNumberField
                            value={trustMobileNumberState}
                            label={"Contact Number"}
                            defaultCountry={"in"}
                            placeholder={"Enter Contact number "}
                            onChange={(phone, country) => {
                              setTrustMobileNumberState(phone);
                              formik.setFieldValue(
                                "trustCountryCode",
                                country?.countryCode
                              );
                              formik.setFieldValue(
                                "trustDialCode",
                                country?.dialCode
                              );
                              formik.setFieldValue(
                                "trustNumber",
                                phone?.replace(country?.dialCode, "")
                              );
                            }}
                            required
                          />
                        </Col>
                        <Col md={6}>
                          <CustomTextField
                            label="Type"
                            name="trustType"
                            type="text"
                            disabled
                          />
                        </Col>
                        <Col md={6}>
                          <CustomTextField
                            label="Domain Name"
                            helperText
                            name="domainName"
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(0, 30);
                              setDomainNameState(e.target.value);
                            }}
                            placeholder="Enter Domain Name"
                            type="text"
                          />
                          {checkDomainName?.data?.data?.result ? (
                            <div
                              className="text-success"
                              style={{
                                font: "normal normal bold 12px/33px Noto Sans",
                              }}
                            >
                              Available{" "}
                              <CheckCircle size={"15px"} strokeWidth={"3px"} />
                            </div>
                          ) : (
                            domainNameState !== "" && (
                              <div
                                className="text-danger"
                                style={{
                                  font: "normal normal bold 12px/33px Noto Sans",
                                }}
                              >
                                Not-Available{" "}
                                <XCircle size={"15px"} strokeWidth={"3px"} />{" "}
                              </div>
                            )
                          )}
                        </Col>
                        <Col md={6}>
                          <CustomTextField
                            label="Facebook Link"
                            name="facebookLink"
                            type="text"
                            placeholder="Enter facebook link"
                          />
                        </Col>
                        <Col xs={12}>
                          <TextArea
                            label="About"
                            name="about"
                            rows="6"
                            placeholder="Enter about"
                            requiredF
                            className="text-area form-control"
                          />
                        </Col>
                      </Row>
                      {/* About user Container */}
                      <Row className="mt-2">
                        <Col xs={12}>
                          <div className="heading_div existLabel">
                            ABOUT USER
                            <hr />
                          </div>
                        </Col>
                        <Row className="">
                          <Col xs={12} className="">
                            <Row>
                              <Col xs={12} md={6} lg={4} className="">
                                <CustomTextField
                                  label={"Name"}
                                  placeholder="Enter name "
                                  name="name"
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      30
                                    ))
                                  }
                                  requiredF
                                />
                              </Col>
                              <Col xs={12} md={6} lg={4} className="">
                                <CustomTextField
                                  label={"Email"}
                                  name="email"
                                  placeholder="Enter email "
                                  requiredF
                                />
                              </Col>
                              <Col xs={12} md={6} lg={4} className="">
                                <CustomCountryMobileNumberField
                                  value={phoneNumber}
                                  label={"Mobile Number"}
                                  defaultCountry={"in"}
                                  placeholder={"Enter phone number "}
                                  onChange={(phone, country) => {
                                    setPhoneNumber(phone);
                                    formik.setFieldValue(
                                      "countryCode",
                                      country?.countryCode
                                    );
                                    formik.setFieldValue(
                                      "dialCode",
                                      country?.dialCode
                                    );
                                    formik.setFieldValue(
                                      "mobileNumber",
                                      phone?.replace(country?.dialCode, "")
                                    );
                                  }}
                                  required
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                      {/* About user Container */}
                      <Row className="mt-0">
                        <Col xs={12} className="mt-4">
                          <div className="heading_div">
                            LOCATION
                            <hr />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <TextArea
                            label="Address"
                            name="address"
                            rows="4"
                            placeholder="Enter address"
                            className="text-area form-control"
                          />
                        </Col>
                        <Col xs="12" md={6}>
                          <label>Location*</label>
                          <CustomLocationField
                            setFieldValue={formik.setFieldValue}
                            error={formik}
                            values={formik?.values}
                          />
                          <div
                            style={{
                              height: "20px",
                              font: "normal normal bold 11px/33px Noto Sans",
                            }}
                          >
                            {formik.errors.location &&
                              formik.touched.location && (
                                <div className="text-danger">
                                  Location is required
                                </div>
                              )}
                          </div>
                        </Col>
                        <>
                          <Col xs={12} md={6} className="opacity-75">
                            <CustomTextField
                              label={"City"}
                              placeholder={"Enter City"}
                              name="city"
                              disabled
                            />
                          </Col>
                          <Col xs={12} md={6} className="opacity-75">
                            <CustomTextField
                              label={"State"}
                              placeholder={"Enter State"}
                              name="state"
                              disabled
                            />
                          </Col>
                        </>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="heading_div mt-4">
                            FACILITIES
                            <hr />
                          </div>
                        </Col>
                        {formik?.values?.trustFacilities?.map((item, idx) => {
                          console.log("item", item);
                          return (
                            <Col
                              lg={3}
                              md={4}
                              sm={6}
                              key={idx}
                              className="position-relative facilityCol"
                            >
                              <Button
                                className="removeImageButton"
                                onClick={(e) => {
                                  removeFacilityF(item, formik);
                                }}
                              >
                                <X color="#ff8744" stroke-width="3" />
                              </Button>
                              <div className="py-4">
                                <div className="trust_img">
                                  <img
                                    src={
                                      item?.facilityImagePreview?.preview ?? ""
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="py-1">
                                  <div className="temple_name">
                                    {item?.name}
                                  </div>
                                  {item?.startTime &&
                                    item?.startTime !== "" &&
                                    item?.endTime &&
                                    item?.endTime !== "" && (
                                      <div className="temple_time">
                                        Timings : {item?.startTime} to{" "}
                                        {item?.endTime}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                        <Col lg={3} md={4} sm={6}>
                          <div
                            className="py-4"
                            onClick={() => {
                              dispatch(handleRegister(formik.values));
                            }}
                          >
                            <Link to="/facility">
                              <div className="facility_button">
                                <Plus />
                              </div>
                              <div className="facility_add">
                                + Add Facilities
                              </div>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      {/* temple image */}
                      <Row>
                        <div className="heading_div mt-4">
                          Temple Image &nbsp;
                          <span
                            style={{
                              color: "#9f9f9f",
                              textTransform: "capitalize",
                              fontSize: "22px",
                            }}
                          >
                            {" "}
                            * Please upload temple Image 1280*480 dimension *
                          </span>
                          <hr />
                        </div>
                        <Col>
                          <Dropzone
                            acceptFile="image/png, image/gif, image/jpeg"
                            type={editImage}
                            onlyImage
                            imageName="ProfileImages"
                            bg_plus={bg_plus}
                            imageSpinner={profileImageSpinner}
                            setImageSpinner={setProfileImageSpinner}
                            isProfile={formik?.values?.profileImage}
                            setDeletedImages={setDeletedImages}
                            editedFileNameInitialValue={
                              formik.values.profileImage
                                ? formik.values.profileImage
                                : null
                            }
                            defaultImages={defaultImages}
                            randomNumber={randomNumber}
                            fileName={(file, type) => {
                              formik.setFieldValue("profileImage", `${file}`);
                              formik.setFieldValue("type", type);
                            }}
                            removeFile={(fileName) => {
                              formik.setFieldValue("profileImage", "");
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="heading_div mt-4">
                            DOCUMENTS AND IMAGES
                            <hr />
                          </div>
                        </Col>
                        <Col md={6}>
                          <Dropzone
                            acceptFile="image/png, image/gif, image/jpeg"
                            multiple
                            onlyImage
                            imageName="TrustImages"
                            type={editImage}
                            bg_plus={bg_plus}
                            imageSpinner={imageSpinner}
                            setImageSpinner={setImageSpinner}
                            setDeletedImages={setDeletedImages}
                            editedFileNameInitialValue={
                              formik.values.images ? formik.values.images : null
                            }
                            defaultImages={defaultImages}
                            randomNumber={randomNumber}
                            fileName={(file, type) => {
                              formik.setFieldValue("images", [
                                ...formik.values.images,
                                `${file}`,
                              ]);
                              formik.setFieldValue("type", type);
                            }}
                            removeFile={(fileName) => {
                              const newFiles = [...formik.values.images];
                              const updatedFiles = newFiles.filter(
                                (img) => !img.includes(fileName)
                              );
                              formik.setFieldValue("images", updatedFiles);
                            }}
                          />
                        </Col>
                        {/* Doc Col */}
                        <Col lg={6}>
                          <Row>
                            <Col xs={12}>
                              <Row className="d-flex align-items-center">
                                <Col xs={10} className="pe-0">
                                  <div>
                                    <label>Documents</label>
                                  </div>
                                  <input
                                    ref={uploadDocuments}
                                    type={"file"}
                                    name="documents"
                                    accept=""
                                    onChange={(e) => {
                                      if (e.target.files?.length) {
                                        handleUpload(
                                          e.target.files[0],
                                          "document",
                                          formik
                                        );
                                      }
                                    }}
                                  />
                                </Col>
                                <Col xs={2} className="pt-1 align-self-end">
                                  <Button
                                    className="bg_submit w-auto px-4"
                                    onClick={() =>
                                      uploadDocuments.current.click()
                                    }
                                  >
                                    Browse
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className=" row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5  gap-1 mt-2 text-break">
                            {[...formik.values.documents]?.map((item, idx) => (
                              <Col
                                className="pdfDiv position-relative cursor-pointer mt-lg-4"
                                key={idx}
                              >
                                <Button
                                  className="removePDFButton"
                                  onClick={() => {
                                    removeDocumentFile(item, formik);
                                  }}
                                >
                                  <X stroke-width={3} />
                                </Button>
                                <div className="">
                                  <img src={pdfIcon} width={50} alt="" />
                                </div>
                                <div className="docFileName">{item}</div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                        {/* Doc Col */}
                      </Row>

                      <div className="text-center mt-5 pt-5">
                        <Button className="bg_submit" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Container>
      </Container>
    </WrapperRegisterNow>
  );
}

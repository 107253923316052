// ** Reducers Imports
import authSlice from './authSlice'
import facilitySlice from './facilitySlice'

const rootReducer = {
 
  auth:authSlice,
  trustRegister:facilitySlice
}

export default rootReducer

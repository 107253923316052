import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const VERIFICATION = "/public/user/verify-email-token";

export const emailVerification = async (payload) => {
  try {
    const response = await axios.post(`${VERIFICATION}`, payload);
    return response?.data?.data;
  } catch (error) {
    throw error;
  }
};

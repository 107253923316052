import React from "react";
import styled from "styled-components";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Wrapper = styled.div`
  label {
    /* margin-bottom: 0px; */
    font: normal normal 800 18px/64px Noto Sans;
  }
  input::placeholder {
    opacity: 0.5;
    color: #c4c4c4;
  }
  .react-tel-input {
    color: #583703 !important;
    border: none !important;
    height: 38px !important;
    background-color: #f8f8f8 !important;
    font: normal normal 600 18px/38px Noto Sans;
    border-radius: 5px;
  }
  .react-tel-input .form-control {
    width: inherit;
    font: normal normal 600 18px/38px Noto Sans;
  }
  input {
    color: #583703 !important;
    border: none !important;
    background-color: #f8f8f8 !important;
    font: normal normal 600 18px/38px Noto Sans;
  }
  .react-tel-input .flag-dropdown {
    background-color: #f8f8f8 !important;
    border: none !important;
    border-right: 1px solid black !important;
  }
  .react-tel-input .selected-flag:hover {
    background-color: #f8f8f8 !important;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: #f8f8f8 !important;
  }
  .country-list {
    background-color: #f8f8f8 !important;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #ff8744 !important;
      border-radius: 5px;
      height: 50px;
      width: 10px !important;
    }
  }
  .react-tel-input .country-list .country:hover {
    background-color: #ff8744 !important;
    color: #fff;
  }
  .react-tel-input .country-list .country:hover .dial-code {
    color: #fff !important;
  }
  .dial-code {
    color: #583703 !important;
  }
  .react-tel-input .country-list .country.highlight {
    background-color: #ff8744 !important;
  }
  .react-tel-input .country-list .country.highlight > span {
    font: normal normal 600 18px/38px Noto Sans;
    color: #ffffff !important;
  }
  .react-tel-input .country-list .search {
    padding: 0.5rem 1.5rem 0.5rem 0rem;
  }
  .react-tel-input .country-list .search-box {
    width: 100%;
  }
`;
const CustomCountryMobileNumberField = ({
  label,
  placeholder,
  formik,
  required,
  value,
  defaultCountry,
  ...props
}) => {
  return (
    <Wrapper>
      {label && (
        <label>
          {`${label}`}
          {required && "*"}
        </label>
      )}
      <PhoneInput
        value={value}
        enableSearch
        disableSearchIcon
        placeholder={placeholder}
        country={defaultCountry}
        {...props}
      />
    </Wrapper>
  );
};

export default CustomCountryMobileNumberField;

import React from "react";
import styled from "styled-components";
import verification from "../../assets/images/icons/ic_email_verification.svg";
import verificationFailed from "../../assets/images/icons/ic_email_verification_failed.svg";
import Logo from "../../assets/main-logo.png";
import { useQuery } from "@tanstack/react-query";
import { emailVerification } from "../../component/api";
import { useLocation, useParams } from "react-router-dom";

const Wrapper = styled.div`
  height: 100dvh;
  background: #ffd2ba;
  display: grid;
  place-items: center;
  .center-div {
    padding: 0rem 6rem 2rem;
    text-align: center;
  }
  .main-div {
    border-radius: 5px;
    padding: 1rem 2rem;
    background: #ffffff;
  }
  .verification-image {
    width: 80px;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .Logo {
    width: 130px;
  }
  h3 {
    color: #ff8744;
    font-weight: 600;
  }
  p {
    font-size: 20px;
  }
  @media screen and (max-width: 900px) {
    .center-div {
      padding: 0rem 1rem 2rem;
    }
  }
  @media screen and (max-width: 760px) {
    .center-div {
      padding: 0rem 0rem 2rem;
    }
  }
`;

const VerifyPage = () => {
  const history = useLocation();

  const searchParams = new URLSearchParams(history.search);
  const token = searchParams.get("token");

  const { data: isVerifiedMail } = useQuery(
    ["emailVerification", token],
    async () => await emailVerification({ verificationToken: token })
  );

  return (
    <Wrapper>
      <div className="main-div">
        <img src={Logo} alt="Logo" className="Logo" />
        <div className="center-div">
          {isVerifiedMail ? (
            <>
              <img src={verification} alt="" className="verification-image" />
              <h3>Email Successfully Verified</h3>
              <p>
                Your email has been verified please log back in your app, thank
                you.
              </p>
            </>
          ) : (
            <>
              <img
                src={verificationFailed}
                alt=""
                className="verification-image"
              />
              <h3>Verification Failed</h3>
              <p>
                Your email has not been verified please log back in your app,
                thank you.
              </p>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default VerifyPage;

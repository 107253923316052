import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;


const TRUST_DETAIL = 'admin/public/get-trust'

export const getTrustDetail = async (payload) => {
    const response = await axios.get(`${TRUST_DETAIL}/${payload}`);
    return response?.data?.data;
  };